const isRandomlyTrue = Math.random() < 1.5 / 100;
const { trackJs, TrackJS, trackjs } = window.tv2 || {};
const { enabled, application } = trackJs || TrackJS || trackjs || {};

const fullSampleUrls = [
  '/udland/2020-08-18-den-brutale-gaardbestyrer',
  'article/d6dc3efcc804c12652bf2fdaa59609294df3a42f',
];
const isFullSample = fullSampleUrls.some((url) =>
  window.location.href.match(url),
);

if ((isRandomlyTrue && enabled) || isFullSample) {
  // eslint-disable-next-line no-underscore-dangle
  window._trackJs = window._trackJs || {
    token: '8555ea1fb34d4e6eae4f3cabfba6ca3c',
    application,
  };
  window.tv2.utils.loadScript('https://cdn.trackjs.com/agent/v3/latest/t.js');
}
